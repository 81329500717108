import * as yup from 'yup';
import moment from 'moment';
import { PROJECT_TYPES } from 'features/base/constants/project-types';
import { PROJECT_BILLING_TYPES } from 'features/base/constants/project-billing-types';
import { AMOUNT_RANGES } from 'features/base/constants/amount-types';
/**
 * Function for project form validation
 */
const projectFormValidation = yup.object().shape({
  name: yup
    .string()
    .required('Please enter valid project name')
    .max(50, ({ max }) => `Name must be at maximum of ${max} characters`),
  description: yup
    .string()
    .required('Please enter valid project description')
    .min(15, ({ min }) => `Description must be at least ${min} characters`)
    .max(150, ({ max }) => `Description must be at maximum of ${max} characters`),
  type: yup.string().required('Please select a project type'),
  jiraKey: yup.string().when('jiraEnabled', {
    is: true,
    then: yup.string().required('Please select a JIRA key'),
  }),
  jiraAssignee: yup.string().when('jiraEnabled', {
    is: true,
    then: yup.string().required('Please select a JIRA assignee'),
  }),
  // if type is PROJECT_TYPES.CLIENT then billingType is required and billingType must be one of PROJECT_BILLING_TYPES
  billingType: yup
    .string()
    .when('type', {
      is: PROJECT_TYPES.CLIENT,
      then: yup.string().required('Please select a billing type'),
    })
    .oneOf([...Object.values(PROJECT_BILLING_TYPES)], 'Please select a valid billing type'),
  projectValue: yup.number().when('billingType', {
    is: PROJECT_BILLING_TYPES.FIXED_BID,
    then: yup
      .number()
      .min(AMOUNT_RANGES.MIN_AMOUNT, `Minimum project value is $${AMOUNT_RANGES.MIN_AMOUNT}`)
      .max(AMOUNT_RANGES.MAX_AMOUNT, `Maximum project value is $${AMOUNT_RANGES.MAX_AMOUNT}`)
      .required('Please enter an project value')
      .typeError('Project value must be a number'),
  }),
  departments: yup.array().min(1, 'Please select at least one department'),
  startDate: yup
    .date()
    .required('Please select a project start date')
    .test({
      name: 'Less than end date',
      exclusive: false,
      params: {},
      message: 'Cannot exceed or be equal to end date',
      test(value, context) {
        const startDate = moment(value).format('YYYY-MM-DD');
        const endDate = moment(context.parent.endDate).format('YYYY-MM-DD');
        return moment(startDate).isSameOrBefore(moment(endDate));
      },
    }),
  endDate: yup
    .date()
    .required('Please select a project end date')
    .test({
      name: 'Greater than start date',
      exclusive: false,
      params: {},
      message: 'Cannot be equal or prior to start date',
      test(value, context) {
        const startDate = moment(context.parent.startDate).format('YYYY-MM-DD');
        const endDate = moment(value).format('YYYY-MM-DD');
        return moment(startDate).isSameOrBefore(moment(endDate));
      },
    }),
});
/**
 * Edit project form validation
 */
export const editProjectFormValidation = yup.object().shape({
  name: yup
    .string()
    .required('Please enter valid project name')
    .max(50, ({ max }) => `Name must be at maximum of ${max} characters`),
  description: yup
    .string()
    .required('Please enter valid project description')
    .min(15, ({ min }) => `Description must be at least ${min} characters`)
    .max(150, ({ max }) => `Description must be at maximum of ${max} characters`),
  type: yup.string().required('Please select a project type'),
  jiraKey: yup.string().when('jiraEnabled', {
    is: true,
    then: yup.string().nullable().required('Please enter a jira key'),
  }),
  jiraAssignee: yup.string().when('jiraEnabled', {
    is: true,
    then: yup.string().required('Please select a jira assignee'),
  }),
  // if type is PROJECT_TYPES.CLIENT then billingType is required and billingType must be one of PROJECT_BILLING_TYPES
  billingType: yup
    .string()
    .when('type', {
      is: PROJECT_TYPES.CLIENT,
      then: yup.string().required('Please select a billing type'),
    })
    .oneOf([...Object.values(PROJECT_BILLING_TYPES)], 'Please select a valid billing type'),
  projectValue: yup.number().when('billingType', {
    is: PROJECT_BILLING_TYPES.FIXED_BID,
    then: yup
      .number()
      .min(AMOUNT_RANGES.MIN_AMOUNT, `Minimum project value is $${AMOUNT_RANGES.MIN_AMOUNT}`)
      .max(AMOUNT_RANGES.MAX_AMOUNT, `Maximum project value is $${AMOUNT_RANGES.MAX_AMOUNT}`)
      .required('Please enter an project value')
      .typeError('Project value must be a number'),
  }),
  departments: yup.array().min(1, 'Please select at least one department'),
  startDate: yup
    .date()
    .typeError('Please select a start date in the valid format (DD-MM-YYYY)')
    .required('Please select a project start date')
    .test({
      name: 'Less than end date',
      exclusive: false,
      params: {},
      message: 'Cannot exceed or be equal to end date',
      test(value, context) {
        const startDate = moment(value).format('YYYY-MM-DD');
        const endDate = moment(context.parent.endDate).format('YYYY-MM-DD');
        return moment(startDate).isSameOrBefore(moment(endDate));
      },
    }),
  endDate: yup
    .date()
    .typeError('Please select a end date in the valid format (DD-MM-YYYY)')
    .required('Please select a project end date')
    .test({
      name: 'Greater than start date',
      exclusive: false,
      params: {},
      message: 'Cannot be equal or prior to start date',
      test(value, context) {
        const startDate = moment(context.parent.startDate).format('YYYY-MM-DD');
        const endDate = moment(value).format('YYYY-MM-DD');
        return moment(startDate).isSameOrBefore(moment(endDate));
      },
    }),
});
/**
 * Popup form validation
 */
export const popUpFormValidation = yup.object().shape({
  hours: yup
    .number()
    .required('Please enter hour')
    .typeError('Hour must be a number')
    .test('greaterThanZero', 'Hour must be greater than 0', (value) => value > 0)
    .test('divisibleBy8', 'Hour must be divisible by 8', (value) => value % 8 === 0),
  ratePerHour: yup
    .number()
    .required('Please enter rate per hour')
    .typeError('Rate per hour must be a number')
    .when('billingType', {
      is: (value) => value !== PROJECT_BILLING_TYPES.FIXED_BID,
      then: yup
        .number()
        .test('greaterThanZero', 'Rate per hour must be greater than 0', (value) => value > 0),
    }),
});
/**
 * Add documents form validation
 */
export const addDocumentsValidation = yup.object().shape({
  tags: yup.array().min(1, 'Please select at least one tag'),
  // Note: since documentFiles and documentLinks are tightly coupled for validation, the full validation is included in documentLinks
  documentFiles: yup.array().of(yup.object()),
  documentLinks: yup
    .array()
    .of(yup.object())
    .test({
      name: 'Length validation',
      exclusive: false,
      params: {},
      message: 'At least one document required',
      test(value, context) {
        // Check if at least one of the arrays is not empty /
        return value?.length > 0 || context.parent?.documentFiles?.length > 0;
      },
    })
    .test({
      name: 'Tags validation',
      exclusive: false,
      params: {},
      message: 'At least one tag required for each document',
      test(value, context) {
        // Check if all documents have at least one tag
        const linkTagsVarified = value?.every((documentFile) => documentFile?.tags?.length > 0);
        const fileTagsVarified = context.parent?.documentFiles?.every(
          (documentFile) => documentFile?.tags?.length > 0
        );
        return linkTagsVarified && fileTagsVarified;
      },
    }),
  linkInput: yup.string().url('Invalid URL format'),
});
/**
 * Edit document form validation
 */
export const editDocumentValidation = yup.object().shape({
  tags: yup.array().min(1, 'Please select at least one tag'),
});
//
export default projectFormValidation;
