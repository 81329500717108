import { Alert, Grid, Switch, FormControlLabel } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import moment from 'moment';
//
import { projectActions } from 'features/projects/slice';
import { notificationActions } from 'features/base/notifications/slice';
import {
  selectUploadedLogo,
  selectAddProjectLoader,
  selectUploadFileLoader,
  selectInternalUsers,
  selectAllDepartments,
  selectJiraProjectsLoader,
  selectJiraProjects,
} from 'features/projects/selectors';
import { selectNotification } from 'features/base/notifications/selectors';
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import {
  Autocomplete,
  Select,
  TextField,
  FileUpload,
  Popup,
  NumericInputField,
} from 'features/base/components/index';
import { PROJECT_TYPES, PROJECT_TYPE_ARRAY } from 'features/base/constants/project-types';
import {
  PROJECT_BILLING_TYPES,
  PROJECT_BILLING_TYPE_ARRAY,
} from 'features/base/constants/project-billing-types';
import DatePicker from 'features/base/components/date-picker';
import ERROR_TYPES from 'features/base/constants/error-types';
import { capitalizeFirstLetter, formattedString } from 'features/base/helpers/strings';
import projectFormValidation from 'features/projects/validation/project-validation';
import {
  PROJECT_LOGO_ALLOWED_FILE_TYPES,
  PROJECT_LOGO_ALLOWED_EXTENSIONS,
} from 'features/base/constants/file-upload';
import {
  getAllowedExtensionsString,
  processFiles,
  verifyExtensions,
} from 'features/base/helpers/file';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { ISO_WITHOUT_TIME } from 'features/base/constants/date-formatting';
import MultiSelectAutoComplete from 'features/base/components/multi-select-auto-complete';
import { selectAllTools } from 'features/tools/selectors';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import { PROJECT_FILE_TYPES } from 'features/base/constants/project-file-types';
import './index.scss';

/**
 * Function that defines the popup form for adding a new project
 * @prop {boolean} addModalOpen - boolean to show/hide the popup
 * @prop {function} setAddModalOpen - function to set the popup state
 * @returns {Popup}
 */
const AddProjectsPopup = ({ addModalOpen, setAddModalOpen }) => {
  const dispatch = useDispatch();
  //
  const tools = useSelector(selectAllTools);
  const departments = useSelector(selectAllDepartments);
  const notification = useSelector(selectNotification);
  const usersList = useSelector(selectInternalUsers);
  const uploadedLogo = useSelector(selectUploadedLogo);
  const loading = useSelector(selectAddProjectLoader);
  const uploadLoading = useSelector(selectUploadFileLoader);
  const jiraProjectsLoading = useSelector(selectJiraProjectsLoader);
  const jiraProjects = useSelector(selectJiraProjects);
  //
  const [logo, setLogo] = useState([]);
  const [fileTypeError, setFileTypeError] = useState('');
  //
  const handleOnClose = () => {
    setAddModalOpen(false);
    dispatch(notificationActions.resetNotification());
    dispatch(projectActions.clearLogo());
    setFileTypeError('');
  };
  //
  useEffect(() => {
    if (addModalOpen && notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      handleOnClose();
    }
  }, [notification]);
  //
  useEffect(() => {
    const validated = verifyExtensions(Object.values(logo), PROJECT_LOGO_ALLOWED_EXTENSIONS);
    if (validated) {
      setFileTypeError(false);
      const formData = processFiles(logo);
      formData.append('fileType', PROJECT_FILE_TYPES.LOGO);
      if (logo.length > 0) {
        dispatch(projectActions.uploadLogo({ files: formData }));
      }
    } else {
      setFileTypeError(true);
    }
  }, [logo]);
  //
  useEffect(() => {
    dispatch(projectActions.getJiraProjects());
  }, []);
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.PROJECT,
          action: PERMISSION_ACTIONS.CREATE,
        },
      ]}
      hide
    >
      <Popup
        open={addModalOpen}
        onClose={handleOnClose}
        title="Add new project"
        mediumSize="1060px"
        titleSx={{ pl: '0.8rem' }}
      >
        <Formik
          initialValues={{
            name: '',
            description: '',
            type: '',
            billingType: '',
            startDate: '',
            endDate: '',
            projectValue: 0,
            jiraKey: '',
            jiraAssignee: '',
            jiraEnabled: false,
            tools: [],
            departments: [],
            tags: [],
            itSupport: false,
          }}
          validationSchema={projectFormValidation}
          onSubmit={(values) => {
            const { jiraEnabled, jiraKey, jiraAssignee, tags, ...rest } = values;
            rest.startDate = moment(values.startDate).format(ISO_WITHOUT_TIME);
            rest.endDate = moment(values.endDate).format(ISO_WITHOUT_TIME);
            rest.tools = values.tools.map((tool) => tool.id);
            rest.departments = values.departments.map((department) => department.id);
            rest.logo = uploadedLogo.map((logoObj) => ({
              ...logoObj,
              url: encodeURI(logoObj.url),
            }));
            dispatch(
              projectActions.addProject(
                jiraEnabled
                  ? {
                      ...rest,
                      jiraKey: jiraKey.split('-')[0].trim(),
                      jiraAssignee,
                    }
                  : {
                      ...rest,
                    }
              )
            );
          }}
        >
          {({ errors, handleChange, handleSubmit, handleBlur, touched, values, setFieldValue }) => (
            <form noValidate onSubmit={handleSubmit}>
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert sx={{ mb: 2 }} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <Grid container spacing={2}>
                <Grid container item md={6} direction="column">
                  <FileUpload
                    setLogo={setLogo}
                    label="Logo"
                    value={values.logo}
                    acceptedTypes={PROJECT_LOGO_ALLOWED_FILE_TYPES}
                    error={fileTypeError}
                    errorMsg={`Allowed extensions are ${getAllowedExtensionsString(
                      PROJECT_LOGO_ALLOWED_EXTENSIONS
                    )}`}
                    inputLabel={
                      uploadedLogo.length > 0
                        ? `${uploadedLogo[0].name}`
                        : uploadedLogo.length === 0 && `Choose logo`
                    }
                    loading={uploadLoading}
                  />
                  <TextField
                    type="text"
                    name="name"
                    value={values.name}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Project Name"
                    placeholder="Enter project name"
                  />
                  <Select
                    id="type"
                    value={values?.type}
                    selectLabel="type"
                    onChange={(event) => {
                      if (event.target.value !== PROJECT_TYPES.CLIENT) {
                        setFieldValue('billingType', '');
                        setFieldValue('projectValue', 0);
                      }
                      setFieldValue('type', event.target.value);
                    }}
                    items={PROJECT_TYPE_ARRAY}
                    textLabel="Type"
                    renderValue={(value) => formattedString(capitalizeFirstLetter(value))}
                    stringFormat={(value) => formattedString(capitalizeFirstLetter(value))}
                    error={Boolean(touched.type && errors.type)}
                    errorMsg={touched?.type && errors?.type}
                    placeholder="Select project type"
                  />
                  {values.type === PROJECT_TYPES.CLIENT && (
                    <Select
                      id="billingType"
                      name="billingType"
                      value={values.billingType}
                      selectLabel="billingType"
                      onChange={(event) => {
                        setFieldValue('billingType', event.target.value);
                        if (event.target.value !== PROJECT_BILLING_TYPES.FIXED_BID) {
                          setFieldValue('projectValue', 0);
                        }
                      }}
                      items={PROJECT_BILLING_TYPE_ARRAY}
                      textLabel="Billing Type"
                      renderValue={(value) => formattedString(capitalizeFirstLetter(value))}
                      stringFormat={(value) => formattedString(capitalizeFirstLetter(value))}
                      error={Boolean(touched.billingType && errors.billingType)}
                      errorMsg={touched?.billingType && errors?.billingType}
                    />
                  )}
                  {values.type === PROJECT_TYPES.CLIENT &&
                    values.billingType === PROJECT_BILLING_TYPES.FIXED_BID && (
                      <NumericInputField
                        type="text"
                        name="projectValue"
                        label="Project Value"
                        value={values.projectValue}
                        error={Boolean(touched.projectValue && errors.projectValue)}
                        helperText={touched.projectValue && errors.projectValue}
                        onChange={(value) => {
                          if (value.floatValue) {
                            setFieldValue('projectValue', value.floatValue);
                          } else {
                            setFieldValue('projectValue', 0);
                          }
                        }}
                        onBlur={handleBlur}
                        thousandSeparator=","
                        prefix="$"
                        placeholder="$"
                      />
                    )}
                  <DatePicker
                    name="startDate"
                    label="Start date"
                    value={values.startDate}
                    onChange={(event) => {
                      setFieldValue('startDate', moment(event?.$d).format(ISO_WITHOUT_TIME));
                    }}
                    onKeyDown={(e) => e.preventDefault()}
                    error={Boolean(touched.startDate && errors.startDate)}
                    errorMsg={touched?.startDate && errors?.startDate}
                    sx={{ pb: 2 }}
                    placeholder="Select start date"
                  />
                  <DatePicker
                    name="endDate"
                    label="End date"
                    value={values.endDate}
                    onChange={(event) => {
                      setFieldValue('endDate', moment(event?.$d).format(ISO_WITHOUT_TIME));
                    }}
                    onKeyDown={(e) => e.preventDefault()}
                    error={Boolean(touched.endDate && errors.endDate)}
                    errorMsg={touched?.endDate && errors?.endDate}
                    sx={{ pb: 2 }}
                    placeholder="Select end date"
                  />
                  <TextField
                    type="text"
                    name="description"
                    value={values.description}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Description"
                    height={90}
                    multiline
                    rows={3}
                  />
                </Grid>
                <Grid container item md={6} direction="column">
                  {values?.type === PROJECT_TYPES.INTERNAL && (
                    <FormControlLabel
                      control={
                        <Switch
                          name="itSupport"
                          checked={values.itSupport}
                          onChange={() => {
                            setFieldValue('itSupport', !values.itSupport);
                          }}
                        />
                      }
                      label="Internal IT Support"
                    />
                  )}
                  <FormControlLabel
                    control={
                      <Switch
                        name="jiraEnabled"
                        checked={values.jiraEnabled}
                        onChange={() => {
                          setFieldValue('jiraEnabled', !values.jiraEnabled);
                        }}
                      />
                    }
                    label="Enable Jira integration"
                  />

                  <Autocomplete
                    id="jiraKey"
                    name="jiraKey"
                    options={
                      jiraProjects?.map((project) => ({
                        id: project.id,
                        label: `${project.key} - ${project.name}`,
                      })) ?? []
                    }
                    label="Jira Project Key"
                    hide={!values.jiraEnabled}
                    value={values.jiraKey}
                    onChange={(_, autoValue) => setFieldValue('jiraKey', autoValue?.label || null)}
                    isOptionEqualToValue={(option, autoValue) =>
                      option.label === autoValue || autoValue === null
                    }
                    error={Boolean(touched.jiraKey && errors.jiraKey)}
                    errorMsg={touched?.jiraKey && errors?.jiraKey}
                    placeholder="Select Jira Key"
                    sx={{
                      pb: 1,
                    }}
                    disabled={jiraProjectsLoading}
                    controlled
                  />

                  <Autocomplete
                    id="jiraAssignee"
                    name="jiraAssignee"
                    options={
                      usersList?.docs?.map((user) => ({
                        id: user.id,
                        label: user.email,
                      })) ?? []
                    }
                    label="Jira Assignee"
                    hide={!values.jiraEnabled}
                    value={values.jiraAssignee}
                    onChange={(_, autoValue) =>
                      setFieldValue('jiraAssignee', autoValue?.label || null)
                    }
                    isOptionEqualToValue={(option, autoValue) =>
                      option.label === autoValue || autoValue === null
                    }
                    error={Boolean(touched.jiraAssignee && errors.jiraAssignee)}
                    errorMsg={touched?.jiraAssignee && errors?.jiraAssignee}
                    placeholder="Select Assignee"
                    sx={{
                      pb: 1,
                    }}
                    controlled
                  />
                  <MultiSelectAutoComplete
                    options={tools?.docs || []}
                    selectedOptions={values.tools}
                    setSelectedOptions={setFieldValue}
                    label="Tools"
                    formikKey="tools"
                    placeholder="Select Tools"
                  />
                  <MultiSelectAutoComplete
                    options={departments || []}
                    selectedOptions={values.departments}
                    setSelectedOptions={setFieldValue}
                    label="Departments"
                    formikKey="departments"
                    placeholder="Select Departments"
                    error={Boolean(touched.departments && errors.departments)}
                    errorMsg={touched.departments && errors.departments}
                  />
                </Grid>
              </Grid>
              <ButtonGrid
                leftButtonText="Cancel"
                rightButtonText={loading ? 'Submitting' : 'Submit'}
                leftOnClick={handleOnClose}
                rightOnClick={null}
                rightIcon={loading ? loaderIcon : null}
                submitDisabled={loading}
              />
            </form>
          )}
        </Formik>
      </Popup>
    </PermissionWrapper>
  );
};
//
export default AddProjectsPopup;
