/* eslint-disable import/no-cycle */
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import request from 'features/base/utils/request';
import { projectActions } from './slice';
import API from './constants';
/**
 * Generator function for getting projects
 * @param {Object} payload
 */
export function* getProjectsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_PROJECTS, payload);
    yield put(projectActions.getAllProjectsSucceeded(response));
  } catch (error) {
    yield put(projectActions.getAllProjectsFailed(error?.message));
  }
}
/**
 * Generator function for adding a project
 * @param {Object} payload
 */
export function* addProjectsGenerator({ payload }) {
  try {
    const response = yield call(request, API.ADD_PROJECT, payload);
    yield put(projectActions.addProjectSucceeded(response));
  } catch (error) {
    yield put(projectActions.addProjectFailed(error?.message));
  }
}
/**
 * Generator function for getting a project by id
 * @param {Object} payload
 */
export function* getOneProjectGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_ONE_PROJECT, { id: payload.projectId });
    yield put(projectActions.getOneProjectSucceeded(response));
  } catch (error) {
    yield put(projectActions.getOneProjectFailed(error?.message));
  }
}
/**
 * Generator function for editing a project
 * @param {Object} payload
 * @param {Object} payload.id
 * @param {Object} payload.body
 */
export function* editProjectsGenerator({ payload }) {
  try {
    const response = yield call(request, API.PATCH_PROJECT, payload);
    yield put(projectActions.editProjectSucceeded(response));
  } catch (error) {
    yield put(projectActions.editProjectFailed(error?.message));
  }
}
/**
 * Generator function for getting external users of a project
 * @param {Object} payload
 */
export function* getClientGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_EXTERNAL_USERS, { projectId: payload.projectId });
    yield put(projectActions.getClientSucceeded(response));
  } catch (error) {
    yield put(projectActions.getClientFailed(error?.message));
  }
}
/**
 * Generator function for getting work allocations of a project
 * @param {Object} payload
 */
export function* getWorkAllocationGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_WORK_ALLOCATION, payload);
    yield put(projectActions.getWorkAllocationSucceeded(response));
  } catch (error) {
    yield put(projectActions.getWorkAllocationFailed(error?.message));
  }
}
/**
 * Generator function for getting work allocations of a project for billing
 * @param {Object} payload
 */
export function* getWorkAllocationBillingGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_WORK_ALLOCATION, payload);
    yield put(projectActions.getWorkAllocationBillingSucceeded(response));
  } catch (error) {
    yield put(projectActions.getWorkAllocationBillingFailed(error?.message));
  }
}
/**
 * Generator function for getting work allocations by user id
 */
export function* getWorkAllocationByUserIdGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_WORK_ALLOCATION, payload);
    yield put(projectActions.getWorkAllocationByUserIdSucceeded(response));
  } catch (error) {
    yield put(projectActions.getWorkAllocationByUserIdFailed(error?.message));
  }
}
/**
 * Generator function for patching work allocation of a project by user id and project id
 */
export function* patchProjectsWorkAllocationGenerator({ payload }) {
  try {
    const response = yield call(request, API.PATCH_ALLOCATION, payload);
    yield put(projectActions.patchProjectWorkAllocationSucceeded(response));
  } catch (error) {
    yield put(projectActions.patchProjectWorkAllocationFailed(error?.message));
  }
}
/**
 * Generator function for patching work allocation of a project by user id and project id in billing stage
 */
export function* patchProjectsWorkAllocationBillingGenerator({ payload }) {
  try {
    const response = yield call(request, API.PATCH_ALLOCATION, payload);
    yield put(projectActions.patchProjectWorkAllocationBillingSucceeded(response));
  } catch (error) {
    yield put(projectActions.patchProjectWorkAllocationBillingFailed(error?.message));
  }
}
/**
 * Generator function for uploading logo
 * @param {Object} payload
 */
function* uploadLogoGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_FILE, payload, true);
    yield put(projectActions.uploadLogoSucceeded(response));
  } catch (error) {
    yield put(projectActions.uploadLogoFailed(error?.message));
  }
}
/**
 * Generator function for uploading documents
 * @param {Object} payload
 */
function* uploadDocumentsGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_FILE, payload, true);
    yield put(projectActions.uploadDocumentsSuccedeed(response));
  } catch (error) {
    yield put(projectActions.uploadDocumentsFailed(error?.message));
  }
}
/**
 * Generator function for getting team create project
 * @param {Object} payload
 */
export function* getCreateTeamProjectGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_ONE_PROJECT, { id: payload.projectId });
    yield put(projectActions.getCreateTeamProjectSucceeded(response));
  } catch (error) {
    yield put(projectActions.getCreateTeamProjectFailed(error?.message));
  }
}
/**
 * Generator function for getting available users
 * @param {Object} payload
 */
export function* getCreateTeamAvailableUsersGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_USERS, payload);
    yield put(projectActions.getCreateTeamAvailableUsersSucceeded(response));
  } catch (error) {
    yield put(projectActions.getCreateTeamAvailableUsersFailed(error?.message));
  }
}
/**
 * Generator function for getting allocated users
 * @param {Object} payload
 */
export function* getCreateTeamSelectedUsersGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_WORK_ALLOCATION, payload);
    yield put(projectActions.getCreateTeamSelectedTeamMembersSucceeded(response));
  } catch (error) {
    yield put(projectActions.getCreateTeamSelectedTeamMembersFailed(error?.message));
  }
}
/**
 * Generator function for getting available user after removing from selected users
 * @param {Object} payload
 */
export function* getCreateTeamRemovedUserGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_USERS, payload?.data);
    yield put(
      projectActions.getCreateTeamRemovedTeamMembersSucceeded({
        response,
        currentAllocations: payload?.currentAllocations,
      })
    );
  } catch (error) {
    yield put(projectActions.getCreateTeamRemovedTeamMembersFailed(error?.message));
  }
}
/**
 * Generator function for creating teams
 * @param {Object} payload
 */
export function* createTeamGenerator({ payload }) {
  try {
    yield call(request, API.POST_CREATE_TEAM, payload);
    yield put(projectActions.createTeamSucceeded());
  } catch (error) {
    yield put(projectActions.createTeamFailed(error?.message));
  }
}
/**
 * Generator function for change ticket assignees ( Notifiers )
 * @param {Object} payload
 */
export function* changeTicketAssigneesGenerator({ payload }) {
  try {
    const response = yield call(request, API.PATCH_PROJECT, payload);
    yield put(projectActions.changeTicketAssigneesSucceeded(response));
  } catch (error) {
    yield put(projectActions.changeTicketAssigneesFailed(error?.message));
  }
}
/**
 * Generator function for change ticket notifiers
 * @param {Object} payload
 */
export function* changeTicketNotifiersGenerator({ payload }) {
  try {
    const response = yield call(request, API.PATCH_PROJECT, payload);
    yield put(projectActions.changeTicketNotifiersSucceeded(response));
  } catch (error) {
    yield put(projectActions.changeTicketNotifiersFailed(error?.message));
  }
}
/**
 * Generator function for change external notifiers
 * @param {Object} payload
 */
export function* changeExternalNotifiersGenerator({ payload }) {
  try {
    const response = yield call(request, API.PATCH_PROJECT, payload);
    yield put(projectActions.changeExternalNotifiersSucceeded(response));
  } catch (error) {
    yield put(projectActions.changeExternalNotifiersFailed(error?.message));
  }
}
/**
 * Generator function for getting internal users
 * @param {Object} payload
 */
export function* getInternalUsersGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_USERS, payload);
    yield put(projectActions.getInternalUsersSucceeded(response));
  } catch (error) {
    yield put(projectActions.getInternalUsersFailed(error?.message));
  }
}
/**
 * Generator function for getting all departments
 * @param {Object} payload
 */
export function* getAllDepartmentsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_DEPARTMENTS, payload);
    yield put(projectActions.getAllDepartmentsSucceeded(response));
  } catch (error) {
    yield put(projectActions.getAllDepartmentsFailed(error?.message));
  }
}
/**
 * Generator function for getting all aggregated allocations
 * @param {Object} payload
 */
export function* getAggregatedAllocationsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_WORK_ALLOCATION, payload);
    yield put(projectActions.getAggregatedAllocationsSucceeded(response));
  } catch (error) {
    yield put(projectActions.getAggregatedAllocationsFailed(error?.message));
  }
}
/**
 * Generator function for adding a project document
 * @param {Object} payload
 */
export function* addProjectDocumentsGenerator({ payload }) {
  try {
    const response = yield call(request, API.ADD_PROJECT_DOCUMENTS, payload);
    yield put(
      projectActions.addProjectDocumentsSucceeded({
        successfullyAddedDocuments: response,
        allDocumentsToBeAdded: payload.documents,
      })
    );
  } catch (error) {
    yield put(projectActions.addProjectDocumentsFailed(error?.message));
  }
}
/**
 * Generator function for editing a project document
 * @param {Object} payload
 */
export function* editProjectDocumentGenerator({ payload }) {
  try {
    const response = yield call(request, API.EDIT_PROJECT_DOCUMENT, payload);
    yield put(projectActions.editProjectDocumentSucceeded(response));
  } catch (error) {
    yield put(projectActions.editProjectDocumentFailed(error?.message));
  }
}
/**
 * Generator function for getting project jira tickets
 */
export function* getJiraTicketsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_JIRA_TICKETS, payload);
    yield put(projectActions.getJiraTicketsSucceeded(response));
  } catch (error) {
    yield put(projectActions.getJiraTicketsFailed(error?.message));
  }
}
/**
 * Generator function for getting a project jira ticket
 */
export function* getJiraTicketGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_JIRA_TICKET, payload);
    yield put(projectActions.getJiraTicketSucceeded(response));
  } catch (error) {
    yield put(projectActions.getJiraTicketFailed(error?.message));
  }
}
/**
 * Generator function for updating project jira tickets
 */
export function* patchJiraTicketsGenerator({ payload }) {
  try {
    const response = yield call(request, API.PATCH_JIRA_TICKETS, payload);
    yield put(projectActions.patchJiraTicketSucceeded(response));
  } catch (error) {
    yield put(projectActions.patchJiraTicketFailed(error?.message));
  }
}
/**
 * Generator function for getting project jira projects
*/
export function* getJiraProjectsGenerator() {
  try {
    const response = yield call(request, API.GET_JIRA_PROJECTS);
    yield put(projectActions.getJiraProjectsSucceeded(response));
  } catch (error) {
    yield put(projectActions.getJiraProjectsFailed(error?.message));
  }
}
/**
 * Redux saga that triggers the above defined functions
 */
export function* projectSaga() {
  yield takeLatest(projectActions.getAllProjects.type, getProjectsGenerator);
  yield takeLatest(projectActions.addProject.type, addProjectsGenerator);
  yield takeLatest(projectActions.getOneProject.type, getOneProjectGenerator);
  yield takeLatest(projectActions.editProject.type, editProjectsGenerator);
  yield takeLatest(projectActions.getClient.type, getClientGenerator);
  yield takeLatest(projectActions.getWorkAllocation.type, getWorkAllocationGenerator);
  yield takeLatest(
    projectActions.getWorkAllocationByUserId.type,
    getWorkAllocationByUserIdGenerator
  );
  yield takeLatest(projectActions.getWorkAllocationBilling.type, getWorkAllocationBillingGenerator);
  yield takeLatest(
    projectActions.patchProjectWorkAllocation.type,
    patchProjectsWorkAllocationGenerator
  );
  yield takeLatest(
    projectActions.patchProjectWorkAllocationBilling.type,
    patchProjectsWorkAllocationBillingGenerator
  );
  yield takeLatest(projectActions.uploadLogo.type, uploadLogoGenerator);
  yield takeLatest(projectActions.uploadDocuments.type, uploadDocumentsGenerator);
  yield takeLatest(projectActions.getCreateTeamProject.type, getCreateTeamProjectGenerator);
  yield takeLatest(
    projectActions.getCreateTeamAvailableUsers.type,
    getCreateTeamAvailableUsersGenerator
  );
  yield takeLatest(projectActions.createTeam.type, createTeamGenerator);
  yield takeLatest(
    projectActions.getCreateTeamSelectedTeamMembers.type,
    getCreateTeamSelectedUsersGenerator
  );
  yield takeEvery(
    projectActions.getCreateTeamRemovedTeamMembers.type,
    getCreateTeamRemovedUserGenerator
  );
  yield takeLatest(projectActions.changeTicketAssignees.type, changeTicketAssigneesGenerator);
  yield takeLatest(projectActions.changeTicketNotifiers.type, changeTicketNotifiersGenerator);
  yield takeLatest(projectActions.changeExternalNotifiers.type, changeExternalNotifiersGenerator);
  yield takeLatest(projectActions.getInternalUsers.type, getInternalUsersGenerator);
  yield takeLatest(projectActions.getAllDepartments.type, getAllDepartmentsGenerator);
  yield takeLatest(projectActions.getAggregatedAllocations.type, getAggregatedAllocationsGenerator);
  yield takeLatest(projectActions.addProjectDocuments.type, addProjectDocumentsGenerator);
  yield takeLatest(projectActions.editProjectDocument.type, editProjectDocumentGenerator);
  yield takeLatest(projectActions.getJiraTickets.type, getJiraTicketsGenerator);
  yield takeLatest(projectActions.getJiraTicket.type, getJiraTicketGenerator);
  yield takeLatest(projectActions.patchJiraTicket.type, patchJiraTicketsGenerator);
  yield takeLatest(projectActions.getJiraProjects.type, getJiraProjectsGenerator);
}
//
export default projectSaga;
