/* eslint-disable react/no-array-index-key */
import React from 'react';
import moment from 'moment';
import { Avatar, Box, Divider, Link, Stack, Typography } from '@mui/material';
import { Download as DownloadIcon } from '@mui/icons-material';
import COLORS from 'features/base/constants/colors';
import { ShimmerTable } from 'react-shimmer-effects';
import { ENVIRONMENT } from 'config';
import { COMMENT_TYPES } from 'features/base/constants/comment-type';
import { DATE_LANGUAGE, DATE_FORMAT_SLASH } from 'features/base/constants/date-formatting';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { saveAs } from 'file-saver';

const CommentView = ({ commentList, commentLoading, isFirst, authUser }) =>
  isFirst && commentLoading ? (
    <ShimmerTable row={5} col={2} />
  ) : (
    Array.isArray(commentList) &&
    [...commentList].reverse()?.map((comment, index) => (
      <React.Fragment key={comment?.id}>
        {index % 5 === 0 && (
          <Box m={2}>
            <Divider sx={{ width: 'auto' }}>
              <Typography sx={{ fontSize: '0.875rem', opacity: 0.6 }} color={COLORS.GREY}>
                {moment(comment?.timestamp).fromNow()}
              </Typography>
            </Divider>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: '0.5rem',
            backgroundColor:
              comment?.addedBy?.id === authUser?.id ? COLORS.PRIMARY_LIGHT : COLORS.SKY_BLUE,
            mt: 2,
            borderRadius: '0.3rem',
          }}
          className="commentBox"
        >
          <Avatar alt="User" src={comment?.addedBy?.profileImage} sx={{ ml: 2, mr: 2 }} />
          <Stack spacing={2}>
            <Box sx={{ ml: 2 }} flexDirection="row" display="flex" alignItems="center">
              <Typography
                ml={2}
                sx={{ fontSize: '0.875rem', fontWeight: 'bold', opacity: 0.8 }}
              >{`${comment?.addedBy?.firstName} ${comment?.addedBy?.lastName} `}</Typography>
              <Typography ml={3} sx={{ fontSize: '0.875rem' }}>
                {new Date(comment?.timestamp).toLocaleDateString(
                  DATE_LANGUAGE.LANGUAGE,
                  DATE_FORMAT_SLASH
                )}{' '}
              </Typography>
              <Typography ml={3} sx={{ fontSize: '0.875rem', opacity: 0.6 }} color={COLORS.GREY}>
                {moment(comment?.timestamp).fromNow()}
              </Typography>
            </Box>
            <Box>
              {comment?.type === COMMENT_TYPES.TEXT && (
                <Box ml={2}>
                  {comment?.message?.split('\n').map((line, idx) => (
                    <Typography
                      key={line + idx}
                      sx={{
                        marginBottom: '0.5rem',
                        wordBreak: 'break-word',
                        fontSize: '0.9rem',
                      }}
                      className="comment-message"
                    >
                      {line
                        .split(/(https?:\/\/[^\s]+)/g) // Split by URLs
                        .map((part, i) =>
                          /https?:\/\/[^\s]+/.test(part) ? (
                            <Link
                              href={part}
                              target="_blank"
                              rel="noopener noreferrer"
                              sx={{ textDecoration: 'underline' }}
                            >
                              {part}
                            </Link>
                          ) : (
                            <React.Fragment key={part + i}>{part}</React.Fragment>
                          )
                        )}
                    </Typography>
                  ))}
                </Box>
              )}
              {comment?.type === COMMENT_TYPES.ATTACHMENT && (
                <Box
                  ml={2}
                  mb={2}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Link
                    href={`${ENVIRONMENT.STORAGE_BUCKET_PREFIX}/${comment?.message}`}
                    underline="none"
                    target="_blank"
                    rel="noreferrer"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      '&:hover': {
                        transform: 'scale(1.1)',
                        transition: 'transform 0.2s',
                      },
                      height: 40,
                      width: 40,
                      mr: 2,
                    }}
                  >
                    <FileIcon
                      extension={comment?.message?.split('.').pop()}
                      {...defaultStyles}
                      type="3d"
                    />
                  </Link>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      '&:hover': {
                        transform: 'scale(1.1)',
                        transition: 'transform 0.2s',
                      },
                    }}
                    onClick={() => {
                      fetch(`${ENVIRONMENT.STORAGE_BUCKET_PREFIX}/${comment?.message}`)
                        .then((response) => response.blob())
                        .then((blob) => {
                          saveAs(blob, comment?.message);
                        });
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '0.75rem',
                        color: COLORS.PRIMARY_MAIN,
                        mr: 1,
                      }}
                    >
                      Download
                    </Typography>
                    <DownloadIcon
                      sx={{
                        fontSize: '1rem',
                        color: COLORS.PRIMARY_MAIN,
                      }}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Stack>
        </Box>
      </React.Fragment>
    ))
  );

export default CommentView;
