/**
 * Defines the API paths for projects
 */
const API = {
  GET_PROJECTS: {
    path: '/projects?:query',
    method: 'GET',
  },
  ADD_PROJECT: {
    path: '/projects',
    method: 'POST',
  },
  GET_ONE_PROJECT: {
    path: '/projects/:id',
    method: 'GET',
  },
  PATCH_PROJECT: {
    path: '/projects/:id',
    method: 'PATCH',
  },
  POST_FILE: {
    path: '/projects/upload',
    method: 'POST',
  },
  GET_EXTERNAL_USERS: {
    path: '/projects/:projectId/clients',
    method: 'GET',
  },
  GET_WORK_ALLOCATION: {
    path: '/allocations?:query',
    method: 'GET',
  },
  PATCH_ALLOCATION: {
    path: '/allocations/:allocationId',
    method: 'PATCH',
  },
  POST_CREATE_TEAM: {
    path: '/projects/:id/allocations',
    method: 'POST',
  },
  GET_USERS: {
    path: '/users?:query',
    method: 'GET',
  },
  GET_DEPARTMENTS: {
    path: '/departments',
    method: 'GET',
  },
  ADD_PROJECT_DOCUMENTS: {
    path: '/projects/:projectId/documents',
    method: 'POST',
  },
  EDIT_PROJECT_DOCUMENT: {
    path: '/projects/:projectId/documents/:documentId',
    method: 'PATCH',
  },
  GET_JIRA_TICKETS: {
    path: '/jira/tickets?:query',
    method: 'GET',
  },
  GET_JIRA_TICKET: {
    path: '/jira/tickets/:id',
    method: 'GET',
  },
  PATCH_JIRA_TICKETS: {
    path: '/jira/tickets/:id',
    method: 'PATCH',
  },
  GET_SUPPORT_TICKETS: {
    path: '/support/tickets?:query',
    method: 'GET',
  },
  GET_JIRA_PROJECTS: {
    path: '/jira/projects',
    method: 'GET',
  },
};
//
export default API;
