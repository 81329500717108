import { createSelector } from '@reduxjs/toolkit';
//
import { initialState } from './slice';
/**
 * Function to select the slice domain
 * @param {Object} state
 * @returns {Object}
 */
const selectDomain = (state) => state['feature/projects'] || initialState;
/**
 * Function to retrieve all projects from the project slice
 */
export const selectAllProjects = createSelector(
  [selectDomain],
  (projectState) => projectState.allProjects
);
/**
 * Function to retrieve the loading state from the project slice
 */
export const selectLoader = createSelector([selectDomain], (projectState) => projectState.loading);
/**
 * Function to retrieve one project state
 */
export const selectProject = createSelector([selectDomain], (projectState) => projectState.project);
/**
 * Function to retrieve is project fetching state
 */
export const selectIsProjectFetching = createSelector(
  [selectDomain],
  (projectState) => projectState.isProjectFetching
);
/**
 * Function to retrieve client state
 */
export const selectClient = createSelector([selectDomain], (projectState) => projectState.client);
/**
 * Function to retrieve is external users fetching state
 */
export const selectIsExternalUsersFetching = createSelector(
  [selectDomain],
  (projectState) => projectState.isExternalUsersFetching
);
/**
 * Function to retrieve work allocation state
 */
export const selectWorkAllocations = createSelector(
  [selectDomain],
  (projectState) => projectState.workAllocations
);
/**
 * Function to retrieve is work allocations fetching state
 */
export const selectIsWorkAllocationFetching = createSelector(
  [selectDomain],
  (projectState) => projectState.isWorkAllocationFetching
);
/**
 * Function to retrieve update work allocation state
 */
export const selectUpdateWorkAllocation = createSelector(
  [selectDomain],
  (projectState) => projectState.updateWorkAllocation
);
/**
 * Function to retrieve update work allocation state billing
 */
export const selectUpdateWorkAllocationBilling = createSelector(
  [selectDomain],
  (projectState) => projectState.billing
);
/**
 * Function to retrieve selected logo state
 */
export const selectUploadedLogo = createSelector(
  [selectDomain],
  (projectState) => projectState.uploadedLogo
);
/**
 * Function to retrieve uploaded documents state
 */
export const selectUploadedDocuments = createSelector(
  [selectDomain],
  (projectState) => projectState.uploadedDocuments
);
/**
 * Function to retrieve the create team project slice
 */
export const selectCreateTeamProject = createSelector(
  [selectDomain],
  (projectState) => projectState.createTeam.project
);
/**
 * Function to retrieve the create team isProjectFetching slice
 */
export const selectCreateTeamIsProjectFetching = createSelector(
  [selectDomain],
  (projectState) => projectState.createTeam.isProjectFetching
);
/**
 * Function to retrieve the create team available users from project slice
 */
export const selectCreateTeamAvailableUsers = createSelector(
  [selectDomain],
  (projectState) => projectState.createTeam.availableUsers
);
/**
 * Function to retrieve the create team isAvailableUsersFetching slice
 */
export const selectCreateTeamIsAvailableUsersFetching = createSelector(
  [selectDomain],
  (projectState) => projectState.createTeam.isAvailableUsersFetching
);
/**
 * Function to retrieve the create team selected team members from project slice
 */
export const selectSelectedTeamMembers = createSelector(
  [selectDomain],
  (projectState) => projectState.createTeam.selectedTeamMembers
);
/**
 * Function to retrieve the create team isSelectedTeamMembersFetching slice
 */
export const selectIsSelectedTeamMembersFetching = createSelector(
  [selectDomain],
  (projectState) => projectState.createTeam.isSelectedTeamMembersFetching
);
/**
 * Function to retrieve the all projects names slice
 */
export const selectIsTeamCreating = createSelector(
  [selectDomain],
  (projectState) => projectState.createTeam.isTeamCreating
);
/**
 * Function to retrieve the all projects names slice
 */
export const selectIsTeamCreatingSucceeded = createSelector(
  [selectDomain],
  (projectState) => projectState.createTeam.isTeamCreatingSucceeded
);
/**
 * Selector for getting loader state of adding a project
 */
export const selectAddProjectLoader = createSelector(
  [selectDomain],
  (userState) => userState.addProjectLoading
);
/**
 * Selector for getting loader state of updating a project
 */
export const selectUpdateProjectLoader = createSelector(
  [selectDomain],
  (userState) => userState.updateProjectLoading
);
/**
 * Function to retrieve departments from the project slice
 */
export const selectAllDepartments = createSelector(
  [selectDomain],
  (projectState) => projectState.allDepartments
);
/**
 * Selector for getting loader state of uploading a logo
 */
export const selectUploadFileLoader = createSelector(
  [selectDomain],
  (projectState) => projectState.uploadFileLoading
);
/**
 * Function to retrieve internal users
 */
export const selectInternalUsers = createSelector(
  [selectDomain],
  (projectState) => projectState.internalUsers
);
/**
 * Function to retrieve is work allocations fetching successful state
 */
export const selectIsWorkAllocationFetchingAllocationReportCompleted = createSelector(
  [selectDomain],
  (projectState) => projectState.isWorkAllocationFetchingAllocationReportCompleted
);
/**
 * Function to retrieve is aggregated allocations from the state
 */
export const selectAggregatedAllocations = createSelector(
  [selectDomain],
  (projectState) => projectState.aggregatedAllocations
);
/**
 * Function to retrieve is aggregated allocations loading from the state
 */
export const selectAggregatedAllocationsLoading = createSelector(
  [selectDomain],
  (projectState) => projectState.aggregatedAllocationsLoading
);
/**
 * Function to retrieve add project documents loading from the state
 */
export const selectAddProjectDocumentsLoading = createSelector(
  [selectDomain],
  (projectState) => projectState.addProjectDocumentsLoading
);
/**
 * Function to retrieve add project documents loading from the state
 */
export const selectEditProjectDocumentsLoading = createSelector(
  [selectDomain],
  (projectState) => projectState.editProjectDocumentLoading
);
/**
 * Function to retrieve loading state when a work allocation is updating
 */
export const selectUpdateWorkAllocationLoader = createSelector(
  [selectDomain],
  (projectState) => projectState.updateWorkAllocation.loading
);
/**
 * Function to retrieve project jira tickets
 */
export const selectProjectJiraTickets = createSelector(
  [selectDomain],
  (projectState) => projectState.jiraTickets
);
/**
 * Function to retrieve a project jira ticket
 */
export const selectProjectJiraTicket = createSelector(
  [selectDomain],
  (projectState) => projectState.jiraTicket
);
/**
 * Function to retireve loading state of getting project jira tickets
 */
export const selectProjectTicketsLoader = createSelector(
  [selectDomain],
  (projectState) => projectState.getJiraTicketsLoading
);
/**
 * Function to retrieve loading state of getting a project jira ticket
 */
export const selectProjectTicketLoader = createSelector(
  [selectDomain],
  (projectState) => projectState.getJiraTicketLoading
);
/**
 * Function to retrieve loading state of updating a project jira ticket
 */
export const selectUpdateProjectTicketLoader = createSelector(
  [selectDomain],
  (projectState) => projectState.patchJiraTicketLoading
);
/**
 * Function to retrieve loading state of changing a ticket assignee
 */
export const selectChangeTicketAssigneeLoader = createSelector(
  [selectDomain],
  (projectState) => projectState.changeTicketAssigneeLoading
);
/**
 * Function to retrieve loading state of changing a ticket notifier
 */
export const selectChangeTicketNotifierLoader = createSelector(
  [selectDomain],
  (projectState) => projectState.changeTicketNotifierLoading
);
/**
 * Function to retrieve loading state of changing a external notifier
 */
export const selectExternalNotifierLoader = createSelector(
  [selectDomain],
  (projectState) => projectState.changeExternalNotifiersLoading
);
/**
 * Function to retrieve jira projects
 */
export const selectJiraProjects = createSelector(
  [selectDomain],
  (projectState) => projectState.jiraProjects
);
/**
 * Function to retrieve loading state of getting jira projects
 */
export const selectJiraProjectsLoader = createSelector(
  [selectDomain],
  (projectState) => projectState.jiraProjectsLoading
);
