import { createSlice } from '@reduxjs/toolkit';
import { PROJECT_PAGINATION_LIMIT } from 'features/base/constants/pagination';
//
import { signInActions } from 'features/sign-in/slice';

export const initialState = {
  allProjects: {},
  project: {},
  internalUsers: {},
  client: {},
  aggregatedAllocations: {},
  aggregatedAllocationsLoading: false,
  workAllocations: {},
  projectToEdit: '',
  allDepartments: [],
  updateWorkAllocation: {
    loading: false,
    success: false,
    team: [],
    project: {},
    hasUnsavedChanges: false,
    workAllocation: {},
  },
  billing: {
    loading: false,
    success: false,
    project: {},
    workAllocation: {},
  },
  uploadedLogo: [],
  uploadedDocuments: [],
  createTeam: {
    project: {},
    isProjectFetching: false,
    availableUsers: {},
    isAvailableUsersFetching: false,
    selectedTeamMembers: {},
    isSelectedTeamMembersFetching: false,
    isTeamCreating: false,
    isTeamCreatingSucceeded: false,
  },
  jiraTickets: {},
  jiraTicket: {},
  loading: false,
  isWorkAllocationFetching: false,
  isProjectFetching: false,
  isExternalUsersFetching: false,
  updateProjectLoading: false,
  addProjectLoading: false,
  uploadFileLoading: false,
  getAllDepartmentsLoading: false,
  addProjectDocumentsLoading: false,
  editProjectDocumentLoading: false,
  getJiraTicketsLoading: false,
  getJiraTicketLoading: false,
  patchJiraTicketLoading: false,
  changeTicketNotifierLoading: false,
  changeTicketAssigneeLoading: false,
  changeExternalNotifiersLoading: false,
  jiraProjects: [],
  jiraProjectsLoading: false,
};
/**
 * Redux slice for the project feature
 */
export const projectSlice = createSlice({
  name: 'feature/projects',
  initialState,
  reducers: {
    getAggregatedAllocations: (state) => ({
      ...state,
      aggregatedAllocationsLoading: true,
    }),
    getAggregatedAllocationsSucceeded: (state, action) => ({
      ...state,
      aggregatedAllocationsLoading: false,
      aggregatedAllocations:
        state.allocations?.docs?.length === 0
          ? action.payload
          : {
              ...action.payload,
              docs: [...(state.allocations?.docs ?? []), ...(action.payload.docs ?? [])],
            },
    }),
    getAggregatedAllocationsFailed: (state) => ({
      ...state,
      aggregatedAllocationsLoading: false,
    }),
    getAllProjects: (state) => ({
      ...state,
      loading: true,
    }),
    getAllProjectsSucceeded: (state, action) => ({
      ...state,
      loading: false,
      allProjects: action?.payload,
    }),
    getAllProjectsFailed: (state) => ({
      ...state,
      loading: false,
      allProjects: {
        ...state.allProjects,
        docs: [],
      },
    }),
    editProject: (state) => ({
      ...state,
      updateProjectLoading: true,
    }),
    editProjectSucceeded: (state, action) => ({
      ...state,
      updateProjectLoading: false,
      allProjects: {
        ...state.allProjects,
        docs: state.allProjects.docs?.map((project) =>
          project.id === action?.payload?.id ? action?.payload : project
        ),
      },
    }),
    editProjectFailed: (state) => ({
      ...state,
      updateProjectLoading: false,
    }),
    addProject: (state) => ({
      ...state,
      addProjectLoading: true,
    }),
    addProjectSucceeded: (state, action) => ({
      ...state,
      addProjectLoading: false,
      allProjects: {
        ...state.allProjects,
        docs:
          state.allProjects?.docs?.length < PROJECT_PAGINATION_LIMIT
            ? [...state.allProjects.docs, action?.payload]
            : state.allProjects.docs,
        totalDocs: state.allProjects.totalDocs + 1,
      },
    }),
    addProjectFailed: (state) => ({
      ...state,
      addProjectLoading: false,
    }),
    clearEditProject: (state) => ({
      ...state,
      projectToEdit: '',
    }),
    getOneProject: (state) => ({
      ...state,
      isProjectFetching: true,
    }),
    getOneProjectSucceeded: (state, action) => ({
      ...state,
      isProjectFetching: false,
      project: action?.payload,
    }),
    getOneProjectFailed: (state) => ({
      ...state,
      isProjectFetching: false,
    }),
    getClient: (state) => ({
      ...state,
      isExternalUsersFetching: true,
    }),
    getClientSucceeded: (state, action) => ({
      ...state,
      isExternalUsersFetching: false,
      client: action?.payload,
    }),
    getClientFailed: (state) => ({
      ...state,
      isExternalUsersFetching: false,
    }),
    getWorkAllocation: (state) => ({
      ...state,
      isWorkAllocationFetching: true,
    }),
    getWorkAllocationSucceeded: (state, action) => ({
      ...state,
      isWorkAllocationFetching: false,
      workAllocations: action?.payload,
    }),
    getWorkAllocationFailed: (state) => ({
      ...state,
      isWorkAllocationFetching: false,
    }),
    resetProjectState() {
      // restore the initial state
      return initialState;
    },
    getWorkAllocationByUserId: (state) => ({
      ...state,
      updateWorkAllocation: {
        ...state.updateWorkAllocation,
        loading: true,
      },
    }),
    getWorkAllocationByUserIdSucceeded: (state, action) => ({
      ...state,
      updateWorkAllocation: {
        ...state.updateWorkAllocation,
        loading: false,
        workAllocation: action?.payload,
      },
    }),
    getWorkAllocationByUserIdFailed: (state) => ({
      ...state,
      updateWorkAllocation: {
        ...state.updateWorkAllocation,
        loading: false,
      },
    }),
    changeHasUnsavedChangesInUpdateWorkAllocation: (state, action) => ({
      ...state,
      updateWorkAllocation: {
        ...state.updateWorkAllocation,
        hasUnsavedChanges: action.payload,
      },
    }),
    patchProjectWorkAllocationInStore(state, action) {
      const { updatedMonthlyAllocations, userId, projectId } = action.payload;
      const updatedWorkAllocations = state.updateWorkAllocation?.workAllocation?.docs?.map(
        (workAllocation) =>
          workAllocation.userId.id === userId && workAllocation.projectId.id === projectId
            ? { ...workAllocation, monthlyAllocations: updatedMonthlyAllocations }
            : workAllocation
      );
      return {
        ...state,
        updateWorkAllocation: {
          ...state.updateWorkAllocation,
          workAllocation: {
            ...state.updateWorkAllocation.workAllocation,
            docs: updatedWorkAllocations,
          },
        },
      };
    },
    getWorkAllocationBilling: (state) => ({
      ...state,
      billing: {
        ...state.billing,
        loading: true,
      },
    }),
    getWorkAllocationBillingSucceeded: (state, action) => ({
      ...state,
      billing: {
        ...state.billing,
        loading: false,
        workAllocation: action?.payload,
      },
    }),
    getWorkAllocationBillingFailed: (state) => ({
      ...state,
      billing: {
        ...state.billing,
        loading: false,
      },
    }),
    patchProjectWorkAllocationBillingInStore(state, action) {
      const { updatedAllocation, userId, projectId } = action.payload;
      const updatedWorkAllocations = state?.billing?.workAllocation?.docs.map((workAllocation) =>
        workAllocation?.userId?.id === userId && workAllocation?.projectId?.id === projectId
          ? { ...workAllocation, monthlyAllocations: updatedAllocation }
          : workAllocation
      );
      return {
        ...state,
        billing: {
          ...state.billing,
          workAllocation: {
            ...state.billing.workAllocation,
            docs: updatedWorkAllocations,
          },
        },
      };
    },
    patchProjectWorkAllocation: (state) => ({
      ...state,
      updateWorkAllocation: {
        ...state.updateWorkAllocation,
        loading: true,
      },
    }),
    patchProjectWorkAllocationSucceeded(state, action) {
      const updatedWorkAllocations = state.updateWorkAllocation?.workAllocation?.docs?.map(
        (workAllocation) =>
          workAllocation?.id === action?.payload?.id ? action?.payload : workAllocation
      );
      return {
        ...state,
        updateWorkAllocation: {
          ...state.updateWorkAllocation,
          loading: false,
          hasUnsavedChanges: false,
          workAllocation: {
            ...state.updateWorkAllocation.workAllocation,
            docs: updatedWorkAllocations,
          },
        },
      };
    },
    patchProjectWorkAllocationFailed: (state) => ({
      ...state,
      updateWorkAllocation: {
        ...state.updateWorkAllocation,
        loading: false,
      },
    }),
    patchProjectWorkAllocationBilling: (state) => ({
      ...state,
      billing: {
        ...state.billing,
        loading: true,
      },
    }),
    patchProjectWorkAllocationBillingSucceeded(state, action) {
      const updatedWorkAllocations = state.billing?.workAllocation?.docs?.map((workAllocation) =>
        workAllocation?.id === action?.payload?.id
          ? { ...workAllocation, monthlyAllocations: action?.payload?.monthlyAllocations }
          : workAllocation
      );
      return {
        ...state,
        billing: {
          ...state.billing,
          loading: false,
          workAllocation: {
            ...state.billing.workAllocation,
            docs: updatedWorkAllocations,
          },
        },
      };
    },
    patchProjectWorkAllocationBillingFailed: (state) => ({
      ...state,
      billing: {
        ...state.billing,
        loading: false,
      },
    }),
    uploadLogo: (state) => ({
      ...state,
      uploadFileLoading: true,
    }),
    uploadLogoSucceeded: (state, action) => ({
      ...state,
      uploadedLogo: action?.payload,
      uploadFileLoading: false,
    }),
    uploadLogoFailed: (state) => ({
      ...state,
      uploadFileLoading: false,
    }),
    clearLogo: (state) => ({
      ...state,
      uploadedLogo: [],
    }),
    uploadDocuments: (state) => ({
      ...state,
      uploadFileLoading: true,
    }),
    uploadDocumentsSuccedeed: (state, action) => ({
      ...state,
      uploadedDocuments: [...state.uploadedDocuments, ...(action?.payload ?? [])],
      uploadFileLoading: false,
    }),
    uploadDocumentsFailed: (state) => ({
      ...state,
      uploadFileLoading: false,
    }),
    setDocuments: (state, action) => ({
      ...state,
      uploadedDocuments: action.payload,
    }),
    getCreateTeamProject: (state) => ({
      ...state,
      createTeam: {
        ...state.createTeam,
        isProjectFetching: true,
      },
    }),
    getCreateTeamProjectSucceeded: (state, action) => ({
      ...state,
      createTeam: {
        ...state.createTeam,
        isProjectFetching: false,
        project: action.payload,
      },
    }),
    getCreateTeamProjectFailed: (state) => ({
      ...state,
      createTeam: {
        ...state.createTeam,
        isProjectFetching: false,
      },
    }),
    getCreateTeamAvailableUsers: (state) => ({
      ...state,
      createTeam: {
        ...state.createTeam,
        isAvailableUsersFetching: true,
      },
    }),
    getCreateTeamAvailableUsersSucceeded: (state, action) => ({
      ...state,
      createTeam: {
        ...state.createTeam,
        isAvailableUsersFetching: false,
        availableUsers: action?.payload,
      },
    }),
    getCreateTeamAvailableUsersFailed: (state) => ({
      ...state,
      createTeam: {
        ...state.createTeam,
        isAvailableUsersFetching: false,
      },
    }),
    setCreateTeamAvailableUsers: (state, action) => ({
      ...state,
      createTeam: {
        ...state.createTeam,
        availableUsers: {
          ...state.createTeam.availableUsers,
          docs: action?.payload,
        },
      },
    }),
    getCreateTeamSelectedTeamMembers: (state) => ({
      ...state,
      createTeam: {
        ...state.createTeam,
        isSelectedTeamMembersFetching: true,
      },
    }),
    getCreateTeamSelectedTeamMembersSucceeded: (state, action) => ({
      ...state,
      createTeam: {
        ...state.createTeam,
        isSelectedTeamMembersFetching: false,
        selectedTeamMembers: action?.payload,
      },
    }),
    getCreateTeamSelectedTeamMembersFailed: (state) => ({
      ...state,
      createTeam: {
        ...state.createTeam,
        isSelectedTeamMembersFetching: false,
      },
    }),
    getCreateTeamRemovedTeamMembers: (state) => ({
      ...state,
      createTeam: {
        ...state.createTeam,
        isAvailableUsersFetching: true,
      },
    }),
    getCreateTeamRemovedTeamMembersSucceeded: (state, action) => ({
      ...state,
      createTeam: {
        ...state.createTeam,
        isAvailableUsersFetching: false,
        availableUsers: {
          ...state.createTeam?.availableUsers,
          docs: [
            ...(state.createTeam?.availableUsers?.docs ?? []),
            ...(action?.payload?.response?.docs?.map((record) => ({
              ...record,
              currentAllocation: action?.payload?.currentAllocations?.find(
                (alloc) => alloc?.id === record?.id
              )?.allocatedPercentage,
            })) ?? []),
          ],
        },
      },
    }),
    getCreateTeamRemovedTeamMembersFailed: (state) => ({
      ...state,
      createTeam: {
        ...state.createTeam,
        isAvailableUsersFetching: false,
      },
    }),
    setCreateTeamSelectedUsers: (state, action) => ({
      ...state,
      createTeam: {
        ...state.createTeam,
        selectedTeamMembers: {
          ...state.createTeam.selectedTeamMembers,
          docs: action?.payload,
        },
      },
    }),
    createTeam: (state) => ({
      ...state,
      createTeam: {
        ...state.createTeam,
        isTeamCreating: true,
      },
    }),
    createTeamSucceeded: (state) => ({
      ...state,
      createTeam: {
        ...state.createTeam,
        isTeamCreating: false,
        isTeamCreatingSucceeded: true,
      },
    }),
    createTeamFailed: (state) => ({
      ...state,
      createTeam: {
        ...state.createTeam,
        isTeamCreating: false,
        isTeamCreatingSucceeded: false,
      },
    }),
    changeTicketAssignees: (state) => ({
      ...state,
      changeTicketAssigneeLoading: true,
    }),
    changeTicketAssigneesSucceeded: (state, action) => ({
      ...state,
      changeTicketAssigneeLoading: false,
      project: {
        ...state.project,
        ticketAssignees: action?.payload?.ticketAssignees || [],
      },
    }),
    changeTicketAssigneesFailed: (state) => ({
      ...state,
      changeTicketAssigneeLoading: false,
    }),
    changeTicketNotifiers: (state) => ({
      ...state,
      changeTicketNotifierLoading: true,
    }),
    changeTicketNotifiersSucceeded: (state, action) => ({
      ...state,
      changeTicketNotifierLoading: false,
      project: {
        ...state.project,
        ticketNotifiers: action?.payload?.ticketNotifiers || [],
      },
    }),
    changeTicketNotifiersFailed: (state) => ({
      ...state,
      changeTicketNotifierLoading: false,
    }),
    changeExternalNotifiers: (state) => ({
      ...state,
      changeExternalNotifiersLoading: true,
    }),
    changeExternalNotifiersSucceeded: (state, action) => ({
      ...state,
      changeExternalNotifiersLoading: false,
      project: {
        ...state.project,
        externalNotifiers: action?.payload?.externalNotifiers || [],
      },
    }),
    changeExternalNotifiersFailed: (state) => ({
      ...state,
      changeExternalNotifiersLoading: false,
    }),
    setIsTeamCreatingSucceeded: (state) => ({
      ...state,
      createTeam: {
        ...state.createTeam,
        isTeamCreatingSucceeded: false,
      },
    }),
    setIsTeamCreatingFailed: (state) => ({
      ...state,
      createTeam: {
        ...state.createTeam,
        isTeamCreatingSucceeded: true,
      },
    }),
    getInternalUsers: (state) => ({
      ...state,
      loading: true,
    }),
    getInternalUsersSucceeded: (state, action) => ({
      ...state,
      loading: false,
      internalUsers: action?.payload,
    }),
    getInternalUsersFailed: (state) => ({
      ...state,
      loading: false,
    }),
    getAllDepartments: (state) => ({
      ...state,
      getAllDepartmentsLoading: true,
    }),
    getAllDepartmentsSucceeded: (state, action) => ({
      ...state,
      getAllDepartmentsLoading: false,
      allDepartments: action?.payload,
    }),
    getAllDepartmentsFailed: (state) => ({
      ...state,
      getAllDepartmentsLoading: false,
    }),
    addProjectDocuments: (state) => ({
      ...state,
      addProjectDocumentsLoading: true,
    }),
    addProjectDocumentsSucceeded: (state, action) => ({
      ...state,
      addProjectDocumentsLoading: false,
      project: {
        ...state.project,
        documents: [
          ...(state.project?.documents ?? []),
          ...(action?.payload?.successfullyAddedDocuments ?? []),
        ],
      },
    }),
    addProjectDocumentsFailed: (state) => ({
      ...state,
      addProjectDocumentsLoading: false,
    }),
    editProjectDocument: (state) => ({
      ...state,
      editProjectDocumentLoading: true,
    }),
    editProjectDocumentSucceeded: (state, action) => ({
      ...state,
      editProjectDocumentLoading: false,
      project: {
        ...state.project,
        documents: state.project?.documents?.map((document) =>
          document?.id === action?.payload?.id ? action?.payload : document
        ),
      },
    }),
    editProjectDocumentFailed: (state) => ({
      ...state,
      editProjectDocumentLoading: false,
    }),
    // clean up reducers
    onProjectViewUnmount: (state) => ({
      ...state,
      allProjects: {},
      internalUsers: [],
      allDepartments: [],
      jiraTickets: {},
      jiraTicket: {},
      project: {},
      workAllocations: {},
    }),
    onUpdateAllocationsUnmount: (state) => ({
      ...state,
      updateWorkAllocation: {},
      workAllocations: {},
    }),
    onBillingPageUnmount: (state) => ({
      ...state,
      workAllocations: {},
    }),
    getJiraTickets: (state) => ({
      ...state,
      getJiraTicketsLoading: true,
    }),
    getJiraTicketsSucceeded: (state, action) => ({
      ...state,
      getJiraTicketsLoading: false,
      jiraTickets:
        state.jiraTickets?.docs?.length === 0
          ? action?.payload
          : {
              ...action?.payload,
              docs: [...(state.jiraTickets?.docs ?? []), ...(action?.payload?.docs ?? [])],
            },
    }),
    getJiraTicketsFailed: (state) => ({
      ...state,
      getJiraTicketsLoading: false,
    }),
    getJiraTicket: (state) => ({
      ...state,
      getJiraTicketLoading: true,
    }),
    getJiraTicketSucceeded: (state, action) => ({
      ...state,
      getJiraTicketLoading: false,
      jiraTicket: action?.payload,
    }),
    getJiraTicketFailed: (state) => ({
      ...state,
      getJiraTicketLoading: false,
    }),
    patchJiraTicket: (state) => ({
      ...state,
      patchJiraTicketLoading: true,
    }),
    patchJiraTicketSucceeded: (state, action) => ({
      ...state,
      patchJiraTicketLoading: false,
      jiraTickets: {
        ...state.jiraTickets,
        docs: state.jiraTickets?.docs.map((ticket) =>
          ticket?.id === action?.payload?.id ? action?.payload : ticket
        ),
      },
    }),
    patchJiraTicketFailed: (state) => ({
      ...state,
      patchJiraTicketLoading: false,
    }),
    resetJiraTickets: (state) => ({
      ...state,
      jiraTickets: {},
    }),
    resetJiraTicket: (state) => ({
      ...state,
      jiraTicket: {},
    }),
    getJiraProjects: (state) => ({
      ...state,
      jiraProjectsLoading: true,
    }),
    getJiraProjectsSucceeded: (state, action) => ({
      ...state,
      jiraProjectsLoading: false,
      jiraProjects: action?.payload,
    }),
    getJiraProjectsFailed: (state) => ({
      ...state,
      jiraProjectsLoading: false,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(signInActions.signIn, () => ({
      ...initialState,
    }));
  },
});
//
export const { actions: projectActions } = projectSlice;
